<template>
  <a-card title="添加微信统计" size="small">
    <template slot="extra">
      <a-range-picker v-model="dateRange" size="small" :allowClear="false" style="margin: -2px 0" @change="queryData" />
    </template>
    <div id="WechatStatsChart" style="height: 260px"></div>
  </a-card>
</template>

<script>
import { dashboardWeChatStats } from "@/apis/stats";
import { Line } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      dateRange: [moment().subtract(6, "days"), moment()],
      chartPlot: null,
    };
  },
  methods: {
    initData() {
      this.chartPlot = new Line("WechatStatsChart", {
        data: [],
        xField: "date",
        yField: "count",
        label: { position: "middle" },
        meta: { count: { alias: "数量" } },
      });
      this.chartPlot.render();
      this.queryData();
    },
    queryData() {
      const startDate = this.dateRange[0].clone();
      const endDate = this.dateRange[1].clone().add(1, "days");
1
      dashboardWeChatStats({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      }).then((data) => {
        const dataItems = [];
        const diffDays = endDate.diff(startDate, "days");
        for (let index = 0; index < diffDays; index++) {
          const date = startDate.clone().add(index, "days").format("YYYY-MM-DD");
          const findIndex = data.findIndex((item) => item.date === date);
          if (findIndex === -1) {
            dataItems.push({ date, count: 0 });
          } else {
            dataItems.push(data[findIndex]);
          }
        }

        this.chartPlot.changeData(dataItems);
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
